import React, { useState, useCallback } from "react";
import {
  Typography,
  Box,
  TextField,
  Chip,
  Button,
  Paper,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { uniq, compact, remove } from "lodash";
import { Send, Clear } from "@material-ui/icons";
import Axios from "axios";
import config from "../../config";

const usePhoneNumbers = () => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  const processPhoneNumber = (num) => {
    const numberString = String("" + num);
    const cleared = String("" + +numberString.replace(/[^0-9.]/g, ""));

    if (cleared.length < 9 || cleared.length > 13) {
      return "";
    }

    if (cleared.startsWith("9") && cleared.length === 9) {
      return "0" + cleared;
    }

    if (cleared.startsWith("2510") && cleared.length === 13) {
      return "0" + cleared.substr(4);
    }

    if (cleared.startsWith("251") && cleared.length === 12) {
      return "0" + cleared.substr(3);
    }

    return num;
  };

  return {
    numbers: phoneNumbers,
    add: (num) => {
      const processedNumber = processPhoneNumber(num);
      setPhoneNumbers((prev) => uniq(compact([...prev, processedNumber])));
    },
    remove: (num) =>
      setPhoneNumbers(remove(phoneNumbers, (val) => num !== val)),
    clear: () => setPhoneNumbers([]),
  };
};

export const SendSMS = () => {
  const numbers = usePhoneNumbers();

  const [sendingSuccess, setSendingSuccuss] = useState(false);

  const [phoneInput, setPhoneInput] = useState();
  const [messageInput, setMessageInput] = useState("");

  const [err, setErrMessage] = useState("");

  const phoneInputChange = useCallback(
    (event) => {
      /** @type {String} */
      const text = event?.target?.value;
      setPhoneInput(text);
      if (text?.includes(",") || text?.includes("\n")) {
        const phoneNumbers = text.split(/,|\n/);
        const lastValue = phoneNumbers.pop();
        phoneNumbers.forEach((phone) => numbers.add(phone));
        setPhoneInput(lastValue);
      }
    },
    [numbers]
  );

  const messageInputChange = (event) => {
    /** @type {String} */
    const text = event?.target?.value;
    setMessageInput(text);
  };

  const submitMessages = useCallback(() => {
    if (numbers?.length === 0 && messageInput.trim === "") {
      setErrMessage(
        numbers?.length === 0 ? "No phone numbers provided" : "Message is empty"
      );
      setTimeout(() => setErrMessage(null), 5000);
      return;
    }

    Axios.post(
      config.ExternAPI("/send-bulk"),
      {
        numbers: numbers?.numbers,
        message: messageInput,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwOTE0MTM0NSIsIm5hbWUiOiJFbG5ldGVjaCBVc2VyIiwiaWF0IjoxNTE2MjM5MDIyfQ.oju48EcxLjdP_-kXVZI9SU1UTi2asAuhEYPRi6suOZY,Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwOTE0MTM0NSIsIm5hbWUiOiJFbG5ldGVjaCBVc2VyIiwiaWF0IjoxNTE2MjM5MDIyfQ.oju48EcxLjdP_-kXVZI9SU1UTi2asAuhEYPRi6suOZY",
        },
      }
    ).then((res) => {
      setMessageInput("");
      setPhoneInput("");
      numbers.clear();

      setSendingSuccuss(true);
      setTimeout(() => setSendingSuccuss(false), 5000);
    });
  }, [messageInput, numbers]);

  const removePhoneFromChips = useCallback(
    (phoneNumber) => {
      numbers.remove(phoneNumber);
    },
    [numbers]
  );

  return (
    <Box padding={3}>
      <Typography variant="h2">Send SMS</Typography>

      <Paper>
        <Box padding={3}>
          <Typography variant="h6">
            Send short messages to multiple phone numbers
          </Typography>
          <TextField
            multiline
            fullWidth
            label="Phone Numbers"
            inputMode="text"
            variant="filled"
            value={phoneInput}
            onChange={phoneInputChange}
          />
          <Typography variant="caption">
            Type or paste in phone numbers separated with comma or new line.
          </Typography>
          <Box height={12} />
          {numbers?.numbers?.length > 0 && (
            <>
              {numbers?.numbers?.map((num, index) => (
                <Chip
                  variant="outlined"
                  key={index + ""}
                  label={num}
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                  onDelete={() => removePhoneFromChips(num)}
                />
              ))}
              <Typography style={{ marginTop: 5 }}>
                {numbers?.numbers?.length} phone numbers.
              </Typography>
              <Box height={12} />
            </>
          )}

          <TextField
            multiline
            fullWidth
            label="Message"
            inputMode="text"
            variant="filled"
            value={messageInput}
            onChange={messageInputChange}
          />
          <Box height={12} />

          <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={submitMessages}
              startIcon={<Send />}
            >
              Send Message
            </Button>
            <Box width={12} />

            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                numbers.clear();
                setPhoneInput("");
                setMessageInput("");
              }}
              startIcon={<Clear />}
            >
              Clear
            </Button>
          </Box>
          <Box height={12} />

          {err && (
            <>
              <Box height={12} />
              <Alert severity="error">{err}</Alert>
            </>
          )}

          {sendingSuccess && (
            <Alert severity="success">Message Sent Successfully</Alert>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
