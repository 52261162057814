import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";

import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";

import moment from "moment";
import Axios from "axios";
import "./analytics.css";

import config from "../../config";

const LabeledCheckbox = ({ label, onChange, value }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={value} onChange={(e) => onChange(e)} />}
      label={label}
    />
  );
};

export const Analytics = () => {
  const [chartData, setChartData] = useState([]);
  const [statuses, setStatuses] = useState({
    total: true,
    delivered: true,
    undeliverable: true,
    sent: true,
    failed: true,
  });

  const [dateFrom, handleFromDateChange] = useState(new Date());
  const [dateTo, handleToDateChange] = useState(new Date());

  useEffect(() => {
    Axios.post(config.API("/graph"), {
      dateFrom,
    })
      .then(({ data: result }) => {
        const formatedResult = result
          ?.map((res) => {
            return {
              ...res,
              total:
                res.deliveredCount +
                res.undeliverableCount +
                res.sentCount +
                res.failedCount,
              date: moment(res._id).format("MMM DD"),
              time: moment(res._id).format("hh:mm A"),
            };
          })
          .reverse();
        setChartData(formatedResult);
      })
      .catch((err) => {})
      .finally(() => {});
  }, [dateFrom]);

  return (
    <Box padding={2}>
      <Typography variant="h2">Analytics</Typography>

      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Date</Typography>
              <DatePicker
                value={dateFrom}
                title="From"
                onChange={handleFromDateChange}
              />
              <DatePicker
                value={dateTo}
                title="To"
                onChange={handleToDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StatusCheckList onChange={setStatuses} />
            </Grid>
            <Grid item xs={12}>
              <Box padding={4} paddingRight={8}>
                <BasicChart chartData={chartData} statuses={statuses} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
function StatusCheckList({ onChange }) {
  const [total, setTotal] = useState(true);
  const [sent, setSentEnabled] = useState(true);
  const [delivered, setDeliveredEnabled] = useState(true);
  const [undeliverable, setUndeliverableEnabled] = useState(true);
  const [failed, setFailedEnabled] = useState(true);

  const onChangeHandle = useCallback(() => {
    if (onChange)
      onChange({
        total,
        sent,
        delivered,
        undeliverable,
        failed,
      });
  }, [total, sent, delivered, undeliverable, failed, onChange]);

  return (
    <>
      <Typography variant="subtitle1">Status</Typography>
      <Box display="flex">
        <LabeledCheckbox
          value={total}
          onChange={(e) => {
            setTotal(!total);
            onChangeHandle();
          }}
          label="All"
        />
        <LabeledCheckbox
          value={sent}
          onChange={(e) => {
            setSentEnabled(!sent);
            onChangeHandle();
          }}
          label="Sent"
        />
        <LabeledCheckbox
          value={delivered}
          onChange={(e) => {
            setDeliveredEnabled(!delivered);
            onChangeHandle();
          }}
          label="Delivered"
        />
        <LabeledCheckbox
          value={undeliverable}
          onChange={(e) => {
            setUndeliverableEnabled(!undeliverable);
            onChangeHandle();
          }}
          label="Undeliverable"
        />
        <LabeledCheckbox
          value={failed}
          onChange={(e) => {
            setFailedEnabled(!failed);
            onChangeHandle();
          }}
          label="Failed"
        />
      </Box>
    </>
  );
}

function BasicChart({ chartData, statuses }) {
  const type = "monotoneX";
  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="2 8" />

        <XAxis name="Date" dataKey="date" />

        <YAxis />

        <Tooltip />

        {statuses.total && (
          <Line type={type} name="Total" dataKey="total" stroke="gray" />
        )}
        {statuses.delivered && (
          <Line
            type={type}
            dot={false}
            name="Delivered Count"
            dataKey="deliveredCount"
            stroke="green"
          />
        )}
        {statuses.undeliverable && (
          <Line
            type={type}
            dot={false}
            name="Undeliverable Count"
            dataKey="undeliverableCount"
            stroke="red"
          />
        )}
        {statuses.sent && (
          <Line
            type={type}
            dot={false}
            name="Sent and Pending Count"
            dataKey="sentCount"
            stroke="blue"
          />
        )}
        {statuses.failed && (
          <Line
            type={type}
            dot={false}
            name="Failed Count"
            dataKey="failedCount"
            stroke="red"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
