import React from 'react'
import { Typography, Box, Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

// import { AppBarWithSearchAndUser } from '../../components/app-bar-with-search-and-user/AppBarWithSearchAndUser'


const PhoneTable = (props) => {

    const tableHead = [
        '#', 'Phone Number', 'Messages Sent'
    ];

    const phoneNumbers = [
        // { _id: '234', phoneNumber: '091293012', messagesCount: '43' },
        // { _id: '234', phoneNumber: '324512352', messagesCount: '1234' },
        // { _id: '234', phoneNumber: '123412342', messagesCount: '32' },
        // { _id: '234', phoneNumber: '091293012', messagesCount: '234' },
        // { _id: '234', phoneNumber: '435234512', messagesCount: '534' },
        // { _id: '234', phoneNumber: '123512345', messagesCount: '512' },
        // { _id: '234', phoneNumber: '532451234', messagesCount: '412' },
    ]

    return (<>
        <Table>
            <TableHead>
                <TableRow>
                    {tableHead.map((value, index) => (
                        <TableCell key={index}>
                            {value}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {phoneNumbers.map((row, index) => (
                    <TableRow
                        hover
                        key={index}
                    >
                        <TableCell padding="default">
                            {index + 1}
                        </TableCell>
                        <TableCell padding="default">
                            {row.phoneNumber}
                        </TableCell>
                        <TableCell padding="default">
                            {row.messagesCount}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </>);
}

export const PhoneNumber = () => {
    return (
        <div>

            {/* <AppBarWithSearchAndUser /> */}

            <Box padding={3} >

                <Paper variant='elevation'>
                    <Box padding={2}>
                        <Typography variant='h4'>
                            Phone Numbers
                        </Typography>
                        <PhoneTable />
                    </Box>
                </Paper>
            </Box>
        </div>
    )
}
