import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Toolbar,
  Drawer,
  AppBar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import {
  Mail,
  Dashboard,
  Send,
  TableChart,
  Phone,
  AccountBox,
  ExitToApp,
  Menu,
} from "@material-ui/icons";
import { useUserSaver } from "../../hooks/user-session";

export const AppHeader = () => {
  const [drawerOpen, openDrawer] = useState(false);

  const mainNavigation = [
    { label: "Dashboard", icon: <Dashboard />, route: "/dashboard" },
    { label: "Send SMS", icon: <Send />, route: "/send-sms" },
    { label: "Phone Numbers", icon: <Phone />, route: "/phone-numbers" },
    { label: "SMS", icon: <Mail />, route: "/sms" },
    { label: "Analytics", icon: <TableChart />, route: "/analytics" },
    { label: "Users", icon: <AccountBox />, route: "/users" },
  ];

  const updateUser = useUserSaver();
  const logout = useCallback(() => {
    updateUser();
    window.location.reload();
  }, [updateUser]);

  return (
    <>
      <Drawer
        open={drawerOpen}
        anchor="left"
        onClose={() => {
          openDrawer(false);
        }}
      >
        <Box width="280px" onClick={() => openDrawer(false)}>
          <Toolbar>
            <Box clone width="160px" padding={2}>
              <img
                className="logo"
                alt="Taxiye SMS Console"
                src="assets/logo-large.png"
              />
            </Box>
          </Toolbar>
          <List>
            {mainNavigation.map(({ label, icon, route }, index) => (
              <ListItem button component={Link} to={route} key={index}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            onClick={() => {
              openDrawer(!drawerOpen);
            }}
            color="inherit"
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Taxiye SMS Console
          </Typography>
        </Toolbar>
      </AppBar>
      <Box height={64} />
    </>
  );
};
