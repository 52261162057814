import React, { useState } from 'react'

import { Box, Card, Grid, CardContent, Typography } from '@material-ui/core'
import { Alarm, Check, Close, Timeline } from '@material-ui/icons'

export const HomeScreen = () => {

    const [reports] = useState([
        {
            label: "Sent",
            icon: <Alarm />,
            reports: [
                { amount: "100", caption: "today" },
                { amount: "300", caption: "yesterday" },
                { amount: "300", caption: "Sep 1" }
            ]
        },
        {
            label: "Delivered",
            icon: <Check />,
            reports: [
                { amount: "100", caption: "today" },
                { amount: "300", caption: "yesterday" },
                { amount: "300", caption: "Sep 1" }
            ]
        },
        {
            label: "Failed",
            icon: <Close />,
            reports: [
                { amount: "10", caption: "today" },
                { amount: "20", caption: "yesterday" },
                { amount: "15", caption: "Sep 1" }
            ]
        },
        {
            label: "Pending",
            icon: <Timeline />,
            reports: [
                { amount: "30", caption: "today" },
                { amount: "26", caption: "yesterday" },
                { amount: "32", caption: "Sep 1" }
            ]
        }
    ])

    return (
        <Box padding={5}>
            <Grid container spacing={5}>
                {reports.map((report, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3} >
                        <Card>
                            <CardContent>
                                <Box display='flex' alignItems='center'>
                                    {React.cloneElement(report.icon, { fontSize: 'large' })}
                                    <Box width={12} />
                                    <Typography variant='h4'>
                                        {report.label}
                                    </Typography>
                                </Box>
                                <Box height={20} />
                                {report.reports.map((t, i) => (
                                    <Box display='flex' key={i} justifyContent='space-between'>
                                        <Typography variant='subtitle2'>{t.caption}</Typography>
                                        <Typography variant='subtitle1'>{t.amount}</Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
