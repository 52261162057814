import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MainNavigation } from './navigation';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#9c33b4',
      main: '#8400a2',
      dark: '#5c0071',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fec633',
      main: '#feb801',
      dark: '#b18000',
      contrastText: '#000',
    },
  }
})

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <MainNavigation />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
