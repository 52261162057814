import { useState } from "react"

const LOCAL_SESSION_KEY = 'userSession'

const userStr = localStorage.getItem(LOCAL_SESSION_KEY)
let data = null;
try {
    if (userStr) {
        data = JSON.parse(userStr)
    }
} catch (e) {
    console.error("user session parse error")
}

function useUser() {
    const [user] = useState(data)
    return user
}

function useUserSaver() {
    return (user) => localStorage.setItem(LOCAL_SESSION_KEY, JSON.stringify(user))
}

export { useUser, useUserSaver }