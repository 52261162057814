import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { AppHeader } from "../screens/app-header/AppHeader";
import { HomeScreen } from "../screens/home/HomeScreen";
import { LoginScreen } from "../screens/login/LoginScreen";
import { SendSMS } from "../screens/send-sms/SendSMS";
import { PhoneNumber } from "../screens/phone-numbers/PhoneNumber";
import { Analytics } from "../screens/analytics/Analytics";
import { Users } from "../screens/users/Users";
import { SMS } from "../screens/sms/SMS";
import { useUser } from "../hooks/user-session";

export const MainNavigation = () => {
  const user = useUser();

  if (!user) {
    return <LoginScreen />;
  }

  return (
    <BrowserRouter>
      <AppHeader />
      <Switch>
        <Route path="/dashboard">
          <HomeScreen />
        </Route>
        <Route path="/send-sms">
          <SendSMS />
        </Route>
        <Route path="/phone-numbers">
          <PhoneNumber />
        </Route>
        <Route path="/sms">
          <SMS />
        </Route>
        <Route path="/analytics">
          <Analytics />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Redirect to="/dashboard" path="/" exact />
      </Switch>
    </BrowserRouter>
  );
};
