import React, { useState, useCallback, useEffect } from "react";
import { useUserSaver } from "../../hooks/user-session";
import { Box, Paper, Typography, TextField, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";

export const LoginScreen = () => {
  const setUser = useUserSaver();
  const [err, setErr] = useState();
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    register("username", { required: "username is required" });
    register("password", { required: "password is required" });
  }, [register]);

  const onSubmit = useCallback(
    (payload) => {
      const { username, password } = payload;
      if (username === "admin" && password === "p@ssSecre1") {
        setUser({
          username,
          type: "admin",
        });
        window.location.reload();
      } else {
        setErr("Wrong username and password.");
        setTimeout(() => setErr(null), 5000);
      }
    },
    [setUser, setErr]
  );

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box
            padding={3}
            display="flex"
            flexDirection="column"
            alignItems="strech"
            width="300px"
          >
            <Box clone maxHeight="120px" alignSelf="center">
              <img src="./assets/logo-large.png" alt="Taxiye SMS Console" />
            </Box>
            <Box height={12} />
            <Typography variant="h5">Login</Typography>
            <Box height={12} />
            <TextField
              fullWidth
              label="Username"
              variant="filled"
              name="username"
              error={errors.username}
              inputRef={register}
            />
            <Box height={12} />
            <TextField
              fullWidth
              label="Password"
              type="password"
              variant="filled"
              name="password"
              error={errors.password}
              inputRef={register}
            />
            <Box height={12} />
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
            {err && (
              <>
                <Box height={12} />
                <Alert severity="error">{err}</Alert>
              </>
            )}
          </Box>
        </Paper>
      </form>
    </Box>
  );
};
