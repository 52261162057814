const host = "";
const hostExtern = "";
function API(path) {
  return `${host}/api${path}`;
}

function ExternAPI(path) {
  return `${hostExtern}${path}`;
}

export default { API, ExternAPI };
