import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  CardContent,
  Card,
  MenuItem,
  Select,
  LinearProgress,
} from "@material-ui/core";
// import { AppBarWithSearchAndUser } from '../../components/app-bar-with-search-and-user/AppBarWithSearchAndUser'
import { useState } from "react";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import Axios from "axios";
import config from "../../config";

const MessagesTable = ({ model, pageNumber, pageNumberChanged, loading }) => {
  const tableHead = ["#", "Phone Number", "Messages", "Status", "Sent On"];

  const data = model?.data;

  if (loading) {
    return (
      <Box width="100%">
        <Box height={16} />
        <LinearProgress />
      </Box>
    );
  }

  if (data?.length === 0) {
    return (
      <Box alignItems="center" textAlign="center" paddingTop={2}>
        <Typography variant="h3">No matching records found.</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box paddingTop={2}>
        <Typography>Page</Typography>

        <Pagination
          variant="outlined"
          style={{ display: "inline-block" }}
          page={pageNumber}
          onChange={(e, page) => pageNumberChanged(page)}
          count={model?.pages}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map((value, index) => (
              <TableCell key={index}>{value}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell padding="default">{index + 1}</TableCell>
              <TableCell padding="default">{row?.to}</TableCell>
              <TableCell padding="default">{row?.message}</TableCell>
              <TableCell padding="default">{row?.status}</TableCell>
              <TableCell padding="default" style={{ width: "140px" }}>
                <Box>{moment(row?.sendOnDate).fromNow()} </Box>
                <Box>
                  {moment(row?.sendOnDate).format("dddd, MMM DD, YYYY ")}{" "}
                </Box>
                <Box>{moment(row?.sendOnDate).format("h:m A")} </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const FilterOptions = ({ mainLabel, valuePair, onValueChange, value }) => {
  return (
    <Box>
      <Typography variant="body2">{mainLabel}</Typography>
      <RadioGroup value={value} onChange={(e) => onValueChange(e.target.value)}>
        <Grid container>
          {valuePair?.map((item, index) => (
            <Grid item key={index}>
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

export const SMS = () => {
  const [typeFilter, setTypeFilter] = useState("ALL");
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [perPageCount, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchFilter, setSearchFilter] = useState(null);
  // const [dateFilter, setDateFilter] = useState(null);

  const [tableModel, setTableModel] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPageNumber(1);
  }, [typeFilter, statusFilter, perPageCount, searchFilter]);

  useEffect(() => {
    const filter = {
      type: typeFilter === "ALL" ? false : typeFilter,
      status: statusFilter === "ALL" ? false : statusFilter,
      searchPhone: searchFilter,
      sizePerPage: perPageCount,
      page: pageNumber,
    };

    const payload = {
      page: 1,
      sizePerPage: 10,
      ...filter,
    };

    setLoading(true);

    Axios.post(config.API("/fetch"), payload)
      .then(({ data: result }) => {
        setTableModel(result);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(false);
      setTableModel({ pages: 0, data: [] });
    };
  }, [typeFilter, statusFilter, searchFilter, perPageCount, pageNumber]);

  return (
    <div>
      {/* <AppBarWithSearchAndUser /> */}
      <Box padding={4}>
        <Typography variant="h3">SMS</Typography>
        <Box height={16} />
        <Card>
          <CardContent>
            <Typography variant="h6">Filtering</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Search"
                  onChange={(event) => {
                    setSearchFilter(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FilterOptions
                  onValueChange={setTypeFilter}
                  mainLabel="Type"
                  value={typeFilter}
                  valuePair={[
                    { label: "All", value: "ALL" },
                    { label: "OTP", value: "OTP" },
                    { label: "Invoice", value: "INVOICE" },
                    { label: "Admin Panel", value: "WEB_BULK" },
                    { label: "Bulk", value: "BULK" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FilterOptions
                  onValueChange={setStatusFilter}
                  mainLabel="Status"
                  value={statusFilter}
                  valuePair={[
                    { label: "All", value: "ALL" },
                    { label: "Pending", value: "PENDING" },
                    { label: "Sent", value: "SENT" },
                    { label: "Delivered", value: "DELIVERED" },
                    { label: "Failed", value: "UNDELIVERABLE" },
                  ]}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="body2">Per Page</Typography>
                <Box flexDirection="row" alignSelf="flex-start">
                  <Select
                    value={perPageCount}
                    onChange={(e) => setPerPage(e.target.value)}
                    fullWidth
                  >
                    {[10, 25, 50, 100, 200].map((val, index) => (
                      <MenuItem key={index + ""} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>

            <MessagesTable
              loading={loading}
              model={tableModel}
              pageNumber={pageNumber}
              pageNumberChanged={setPageNumber}
            />
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};
