import React from 'react'
import { Typography } from '@material-ui/core'

export const Users = () => {
    return (
        <div>
            <Typography variant='h3'>
                Login
            </Typography>
        </div>
    )
}
